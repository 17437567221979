@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #56c4c7;
  --red: #fc6b6b;
  --green: #00d49c;
  --turquoise: #40b0b3;
  --blue: #59a5ff;
  --orange: #ffb526;
  --grey: #878a8a;
  --grey2: #dedede;
  --grey3: #b3b3b3;
  --lightGrey: #e6e6e6;
  --lighterGrey: #f2f2f2;
  --lightestGrey: #fafafa;
  --txtWhite: #fff;
  --txt: #000;

  /* dark mode */
  --dark-gray: #373737;
  --very-dark-gray: #2f2f2f;
  --light-dark-gray: #6b6b6b;
}

@layer base {
  html {
    overflow: auto !important;
  }

  input:read-only {
    @apply bg-[#2f2f2f];
  }

  [type='checkbox'].checkmark-green:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='turquoise' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    @apply text-white;
  }
}
